@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    @apply m-0 bg-slate-100;
}
.tabs-custom-style .ant-tabs-tab{
    padding: 0;
}

.ant-menu-item:nth-last-child(1) {
    padding-left: 20px;
height: 50px;
background-color: white;
}

.ant-menu-item:nth-last-child(1):hover {
    background-color: white;
}
  
.ant-menu-item:nth-last-child(1):active {
    background-color: white;
}

.leads-custom-date-selector > .ant-picker-input > input {
    color: #007AFF !important;
  }
  
.leads-status-dropdown .ant-select-selector {
background-color: transparent !important;
border: none !important;
 }